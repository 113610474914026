<template>
    <div class="organization_register_profile">
        <div class="organization_register_profile__top">

            <div class="organization_register_profile__top__step">
                <span>
                    ログイン情報入力
                </span>
                <span class="active">
                    担当者情報登録
                </span>
                <span>
                    登録内容確認
                </span>
                <span>
                    ご利用登録完了
                </span>
            </div>
            <p class="organization_register_profile__top-title">
                担当者情報登録
            </p>
        </div>
        <div class="organization_register_profile__body">
            <div class="mw-1000">
                <div class="organization_register_profile__body-message">
                    担当者の情報をご入力ください。
                </div>
                <div class="organization_register_profile__body__form">
                    <form class="horizontal" @submit="onSubmit">
                        <div class="form-ctrl">
                          <label for="name" class="text">企業名</label>
                            <div class="form-group">
                                <div class="form-item">
                                    {{ state.organizationData.name }}
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">（企業名）カナ</label>
                            <div class="form-group">
                                {{ state.organizationData.name_kana }}
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">所在地</label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    {{ state.project_place_prefecture_name }} {{ state.project_place_city_name }}
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                担当者氏名<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="family_name"
                                        type="text"
                                        v-model="values.family_name"
                                        name="family_name"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="苗字をご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.family_name"
                                        :message="errors.family_name"
                                    >
                                    </ErrorMessage>
                                </div>
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="given_name"
                                        type="text"
                                        v-model="values.given_name"
                                        name="given_name"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="名前をご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.given_name"
                                        :message="errors.given_name"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                （担当者氏名）カナ<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="family_name_kana"
                                        type="text"
                                        v-model="values.family_name_kana"
                                        name="family_name_kana"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="苗字カナをご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.family_name_kana"
                                        :message="errors.family_name_kana"
                                    >
                                    </ErrorMessage>
                                </div>
                                <div class="form-group">
                                    <Input
                                        maxlength="64"
                                        id="given_name_kana"
                                        type="text"
                                        v-model="values.given_name_kana"
                                        name="given_name_kana"
                                        autocomplete="off"
                                        required="true"
                                        placeholder="名前カナをご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.given_name_kana"
                                        :message="errors.given_name_kana"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label for="name_kana" class="text">
                                連絡先電話番号<span>（必須）</span>
                                <br />
                                <span class="msg_private">※公開されません</span>
                            </label>
                            <div class="form-group-row">
                                <div class="form-group">
                                    <Input
                                        maxlength="11"
                                        autocomplete="off"
                                        id="phone_number"
                                        type="tel"
                                        v-model="values.phone_number"
                                        name="phone_number"
                                        required="true"
                                        placeholder="ハイフンなしでご入力ください"
                                    />
                                    <ErrorMessage
                                        v-if="errors.phone_number"
                                        :message="errors.phone_number"
                                    >
                                    </ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div class="btn_group">
                            <button
                                type="submit"
                                :disabled="isSubmitting"
                                :class="{ submitting: isSubmitting }"
                                class="btn-md btn-blue shadow mh10"
                            >
                                次へ
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register-invited/detail-profile/DetailProfile.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/register-invited/detail-profile/DetailProfile.scss";
</style>
