import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import LanguageUtil from "@/commons/LanguageUtil";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import Organization from "@/domain/entity/Organization";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import Input from "@/presentation/components/input/Input.vue";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
export default defineComponent({
    name: "OrganizationRegisterInvitedDetailProfile",
    components: {
        Breadcrumb: Breadcrumb,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    setup: function () {
        var store = useStore();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "基本情報入力"
                }
            ],
            listCorporation: [],
            listPerfecture: [],
            listCities: [],
            showDropdownList: false,
            namekana: "",
            loadingDataDropdown: false,
            project_place_prefecture_id: "",
            project_place_prefecture_name: "",
            project_place_city_id: "",
            project_place_city_name: "",
            organizationData: {}
        });
        //validate
        var _a = useForm({
            initialValues: new Organization()
        }), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, values = _a.values, setValues = _a.setValues, errors = _a.errors;
        var family_name = useField("family_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var given_name = useField("given_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))).value;
        var family_name_kana = useField("family_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var given_name_kana = useField("given_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)).value;
        var phone_number = useField("phone_number", yup
            .string()
            .required(state.aLang.validation_required)
            .matches(Constant.PHONE_PATTERN, { message: state.aLang.validation_format_phone_number, excludeEmptyString: true })
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        // end validate
        var onSubmit = handleSubmit(function (values) {
            values = Object.assign(store.state.organizationInvitedResgisterData, values);
            values = Object.assign(values, {
                project_place_prefecture_name: state.project_place_prefecture_name,
                project_place_city_name: state.project_place_city_name,
            });
            store.commit("setOrganizationInvitedResgisterData", values);
            router.push(RoutePath.ORGANIZATION_REGISTER_INVITED_CONFIRM);
        });
        function getAllPrefecture() {
            return masterInteractor.getAllPerfecture().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listPerfecture = result.data;
                if (state.project_place_prefecture_id) {
                    state.project_place_prefecture_name = getPerfectureName(state.project_place_prefecture_id);
                }
            });
        }
        function getCitiesByPerfecture(prefecture_id) {
            if (!prefecture_id) {
                return;
            }
            return masterInteractor
                .getAllCitiesByPerfecture(prefecture_id)
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listCities = result.data;
                var city_id = state.project_place_city_id;
                if (city_id && checkExistCities(city_id)) {
                    state.project_place_city_name = getCityName(city_id);
                }
            });
        }
        function checkExistCities(project_place_city_id) {
            var cities = state.listCities.findIndex(function (o) { return o.id == project_place_city_id; });
            return cities != -1 ? true : false;
        }
        function getPerfectureName(id) {
            var perfecture = state.listPerfecture.filter(function (o) { return o.id == id; })[0];
            return perfecture ? perfecture.name : "";
        }
        function getCityName(id) {
            var city = state.listCities.filter(function (o) { return o.id == id; })[0];
            return city ? city.name : "";
        }
        onMounted(function () {
            var organizationInvitedResgisterData = store.state.organizationInvitedResgisterData;
            if (!organizationInvitedResgisterData.email) {
                return router.push(RoutePath.LOGIN);
            }
            state.organizationData = organizationInvitedResgisterData;
            state.project_place_prefecture_id = organizationInvitedResgisterData.project_place_prefecture_id;
            state.project_place_city_id = organizationInvitedResgisterData.project_place_city_id;
            getAllPrefecture();
            getCitiesByPerfecture(state.project_place_prefecture_id);
            if (!organizationInvitedResgisterData.family_name) {
                return;
            }
            var _a = organizationInvitedResgisterData || {}, family_name = _a.family_name, given_name = _a.given_name, family_name_kana = _a.family_name_kana, given_name_kana = _a.given_name_kana, phone_number = _a.phone_number;
            setValues({
                phone_number: phone_number,
                family_name: family_name,
                given_name: given_name,
                family_name_kana: family_name_kana,
                given_name_kana: given_name_kana
            });
        });
        return {
            state: state,
            onSubmit: onSubmit,
            values: values,
            isSubmitting: isSubmitting,
            errors: errors
        };
    }
});
